import { render, staticRenderFns } from "./examination-permits.vue?vue&type=template&id=6fff5672&scoped=true"
import script from "./examination-permits.vue?vue&type=script&lang=ts&setup=true"
export * from "./examination-permits.vue?vue&type=script&lang=ts&setup=true"
import style1 from "./examination-permits.vue?vue&type=style&index=1&id=6fff5672&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fff5672",
  null
  
)

export default component.exports