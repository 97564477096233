import defaultTinymceMenuConfig from "./defaultTinymceMenuConfig";

export default {
  custom_undo_redo_levels: 30,
  license_key: "gpl",
  branding: false,
  promotions: false,
  placeholder: "Type here...",
  statusbar: false,
  // contextmenu_never_use_native: true,
  contextmenu: "",
  height: "100%",
  plugins: "lists table code ",
  toolbar:
    "undo redo | blocks | bold italic underline hr  forecolor backcolor  | alignleft aligncenter alignright alignjustify |  numlist bullist  | removeformat  ",
  menu: {
    ...defaultTinymceMenuConfig,
  },
};
