export default {
  file: {
    title: "File",
    // items:
    //   "newdocument restoredraft | preview | importword exportpdf exportword | print | deleteallconversations",
    items: "",
  },

  edit: {
    title: "Edit",
    // items: "undo redo | cut copy paste pastetext | selectall | searchreplace",
    items: "undo redo | cut copy | selectall | searchreplace",
  },
  view: {
    title: "View",
    items: "",
  },
  insert: {
    title: "Insert",
    // items:
    //   "image link media addcomment pageembed codesample inserttable | math | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
    items: "",
  },
  format: {
    title: "Format",
    items:
      "bold italic underline strikethrough superscript subscript hr codeformat | styles blocks fontsize align lineheight | forecolor backcolor | language | removeformat",
  },
  tools: {
    title: "Tools",
    items: "",
    // items: "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
  },
  table: {
    title: "Table",
    items:
      "inserttable | cell row column | advtablesort | tableprops deletetable",
  },
  help: { title: "Help", items: "help" },
};
